<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/banner_img5.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`/listPage?t=${index}`">{{item.title}}</a>
                <span></span>
              </div>
              <!-- <div class="listSlidListInner">
                <a href="/list?t=1">项目动态</a>
                <span></span>
              </div> -->
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd listHdPage clearfloat">
              <div class="fl listHdName">新闻中心</div>
              <div class="fr listHdGrop"><a href="/">首页</a> · <a href="/list">新闻中心</a> · <span class="txt">公司要闻</span> </div>
            </div>
            <div class="listContainer" v-html="pageCont">
              
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'list',
  data(){
    return {
      pageCont: '',
      active: 0,
      slideHd: {
        title: '公司概况',
        sub: 'COMPANY',
      },
      slideList:[
        {
          title: '公司简介',
          id: '1'
        },
        {
          title: '管理团队',
          id: '2'
        },
        {
          title: '企业文化',
          id: '2'
        },
      ],
      pageSize: 10,
      pageNum: 1,
      total: 20,
      listData: [
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
      ],
      page: [
        '<p>公司简介</p><p>公司简介</p><p>公司简介</p> <p>公司简介</p><p>公司简介</p>',
        '<p>管理团队</p><p>管理团队</p><p>管理团队</p> <p>管理团队</p><p>管理团队</p>',
        '<p>企业文化</p><p>企业文化</p><p>企业文化</p> <p>企业文化</p><p>企业文化</p>',
      ]
    }
  },
  mounted(){
    var _this = this
    if(_this.$route.query.t){
      _this.active = _this.$route.query.t
      // _this.navNameShow = true
      // _this.name1 = _this.$route.query.navName;
      // _this.navNameUrl = _this.navNameList[_this.$route.query.navName]
    }else{
      _this.active = 0
    }
    _this.pageCont = _this.page[_this.active]
  },
  methods: {
    currentChange(e){
      console.log('e :>> ', e);
    }
  }
}
</script>